const size = {
  width: 4,
  height: 3
}
export const photos = [
  {
    src: '/DSC_9167.jpg',
    ...size
  },
  {
    src: '/DSC_9287.jpg',
    ...size
  },
  {
    src: '/IMG_0336.jpg',
    ...size
  },
  {
    src: '/IMG_0372.jpg',
    ...size
  },
  {
    src: '/IMG_0392.jpg',
    ...size
  },
  {
    src: '/IMG_0523.jpg',
    ...size
  },
  {
    src: '/IMG_0702.jpg',
    ...size
  },
  {
    src: '/IMG_5614.jpg',
    ...size
  },
  {
    src: '/IMG_5875.jpg',
    ...size
  }
]
