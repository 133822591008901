import { buttonStyles } from '@/components/Button/Button.style'

import { ButtonProps } from './Button.type'

const Button = ({ children, block, loading, ...rest }: ButtonProps) => (
  <div>
    <button
      {...rest}
      disabled={loading || rest.disabled}
      className={`${buttonStyles({ block })} ${rest.className}`}
    >
      {children}
    </button>
  </div>
)

export default Button
