export const items = [
  {
    id: '1',
    title: '300+',
    subtitle: 'Participantes',
    description: 'Na última edição em 2023, tivemos 330 participantes.'
  },
  {
    id: '2',
    title: '50%+',
    subtitle: 'De participantes',
    description: 'Aumento de 50% de participantes em comparação ao anterior.'
  },
  {
    id: '3',
    title: '170',
    subtitle: 'Empresas',
    description: 'Todas as regiões do país estiveram presentes no último evento.'
  },
  {
    id: '4',
    title: '9',
    subtitle: 'Palestrantes',
    description: 'No último evento de 2023, tivemos 9 palestrantes convidados.'
  }
]
