'use client'
import React, { useState } from 'react'

import { ApplicationForm } from '@/components'
import Image from 'next/image'

const Form = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <div className="flex w-full flex-col justify-center bg-white px-6 py-8 lg:py-6">
      {isSuccess ? (
        <div className="flex flex-col items-center">
          <Image className="mb-10" src="/check.svg" alt="BC Energia" width={100} height={100} />{' '}
          <h2 className="mb-3 text-center text-3xl uppercase text-teal-900">
            Solicitação de inscrição enviada com sucesso!
          </h2>
          <p className="text-center text-teal-900">
            Seus dados foram enviados e serão analisados pela nossa equipe o mais breve possível.
            Obrigado.
          </p>
        </div>
      ) : (
        <div>
          <div className="mb-5">
            <h2 className="mb-3 text-center text-[1.4rem] uppercase leading-[1.8rem] text-teal-900 md:text-3xl md:leading-[2.7rem]">
              Solicite sua inscrição
            </h2>
          </div>
          <ApplicationForm isSuccess={setIsSuccess} />
        </div>
      )}
    </div>
  )
}

export default Form
