'use client'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '@/components'
import { RenderField } from '@/components/Fields/'
import usePostData from '@/hooks/salesForce'

import { fields } from './ApplicationForm.fields'
import { FormData, RequestStatus } from './ApplicationForm.type'

type Props = {
  isSuccess: (status: boolean) => void
}

const ApplicationForm = ({ isSuccess }: Props) => {
  const { register, handleSubmit } = useForm<FormData>()
  const { status, postData } = usePostData()

  const onSubmit = useCallback(
    async (data: FormData) => {
      await postData({
        Cargo: data.office,
        Cidade: data.city,
        Email: data.email,
        Genero: data.genre,
        FaixaEtaria: data.ageGroup,
        Empresa: data.enterprise,
        Estado: data.state,
        Nome: data.name,
        Telefone: data.phone
      })
    },
    [status]
  )

  useEffect(() => {
    isSuccess(status === RequestStatus.Resolved)
  }, [status])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" method="GET">
      <>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {fields.map((field) => (
            <RenderField key={field.name} status={status} register={register} {...field} />
          ))}
        </div>
      </>
      <div>
        <Button block disabled={status === RequestStatus.Pending} type="submit">
          {status === RequestStatus.Pending ? 'Enviando Dados...' : 'Fazer inscrição'}
        </Button>
      </div>
    </form>
  )
}

export default ApplicationForm
