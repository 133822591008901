import { ButtonLink, Heading, Text } from '@/components'
import Image from 'next/image'

import Wrapper from '../Wrapper'

const About = () => (
  <Wrapper id="sobre">
    <div className="flex flex-col gap-16 xl:flex-row">
      <div className="flex basis-1/2 flex-col gap-10">
        <Heading>Grande encontro dos principais players do mercado de energia</Heading>
        <Text>
          O <strong>Encontro dos Agentes do Setor Elétrico no Centro-Oeste</strong> é um evento
          realizado pelo <strong>Grupo BC Energia</strong> com o objetivo de reunir os principais
          players do mercado de energia para discutir as tendências e desafios do setor. O evento
          acontece anualmente, desde 2016, em Goiânia, e conta com a participação de representantes
          de empresas de energia, associações, governo e órgãos reguladores.
        </Text>
        <Text>
          O Grupo BC Energia, como uma das principais empresas do setor elétrico no Brasil, tem o
          compromisso de promover o desenvolvimento do mercado elétrico e incentivar a busca por
          soluções inovadoras e sustentáveis para a geração e comercialização de energia elétrica no
          país.
        </Text>
        <div className="mt-8 text-center xl:text-left">
          <ButtonLink href="#oportunidades">Veja os benefícios</ButtonLink>
        </div>
      </div>
      <div className="basis-1/2">
        <Image
          src="/sobre.jpg"
          alt="Sobre o grupo BC Energia"
          width={696}
          height={619}
          className="w-full"
        />
      </div>
    </div>
  </Wrapper>
)

export default About
