import { Fields } from '@/components/Fields/Fields.type'
import { br_states, ageGroupData, genreData } from '@/data'

import LabelTerms from '../Fields/Terms'

const defaultKeys = {
  required: true,
  configuration: {
    columns: 2
  }
}

export const fields: Array<Fields> = [
  {
    name: 'name',
    placeholder: 'Nome',
    required: true,
    configuration: {
      columns: 1
    }
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    type: 'email',
    ...defaultKeys
  },
  {
    name: 'phone',
    placeholder: 'Telefone',
    ...defaultKeys
  },
  {
    name: 'genre',
    placeholder: 'Gênero',
    type: 'select',
    ...defaultKeys,
    options: genreData
  },
  {
    name: 'ageGroup',
    placeholder: 'Faixa Etária',
    type: 'select',
    ...defaultKeys,
    options: ageGroupData
  },
  {
    name: 'enterprise',
    placeholder: 'Empresa',
    ...defaultKeys
  },
  {
    name: 'office',
    placeholder: 'Cargo',
    ...defaultKeys
  },
  {
    name: 'city',
    placeholder: 'Cidade',
    ...defaultKeys
  },
  {
    name: 'state',
    placeholder: 'Estado',
    type: 'select',
    ...defaultKeys,
    options: br_states
  },
  {
    name: 'terms',
    type: 'checkbox',
    required: true,
    label: <LabelTerms />,
    configuration: {
      columns: 1
    }
  }
]
