'use client'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { items } from '@/components/Navbar/Items'
import Image from 'next/image'

const NavBar: FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => setScrollPosition(window.pageYOffset)

  // Usado para alterar a cor do menu ao rolar a página
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <header>
      <nav
        className={`bg-background fixed inset-x-0 top-0 z-50 flex justify-center transition-all duration-700 ${scrollPosition > 200 ? 'mt-2' : 'mt-8'}`}
      >
        <div
          className={`container hidden items-center justify-between rounded-lg p-6 backdrop-blur-lg transition-all duration-700 ease-in-out md:flex ${scrollPosition > 200 ? 'bg-teal-800 bg-opacity-80' : 'bg-white bg-opacity-30 text-xs lg:text-base'}`}
        >
          <div>
            <Image src="/logo-bc-energia.svg" alt="BC Energia" width={211} height={36} priority />
          </div>
          <div className="group space-x-10 font-bold uppercase">
            {items.map((item) => (
              <Link
                activeClass="item-menu-active"
                key={item.id}
                to={item.target}
                className="cursor-pointer text-white hover:text-gray-200"
                spy={true}
                smooth={true}
                duration={500}
                offset={-90}
                delay={0}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default NavBar
