import { buttonStyles } from '@/components/Button/Button.style'

import { ButtonLinkProps } from './ButtonLink.type'

const ButtonLink = ({ children, block, ...rest }: ButtonLinkProps) => (
  <div>
    <a {...rest} className={`${buttonStyles({ block })} ${rest.className}`}>
      {children}
    </a>
  </div>
)

export default ButtonLink
