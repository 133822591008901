import { useCallback, useState } from 'react'

import { RequestStatus } from '@/components/ApplicationForm/ApplicationForm.type'
import { postSalesForm } from '@/services/salesForce'

import { PostDataProps } from './salesForce.type'

function usePostData() {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle)

  const postData = useCallback(async (data: PostDataProps) => {
    if (status === RequestStatus.Idle) {
      setStatus(RequestStatus.Pending)

      try {
        const res = await postSalesForm(data)

        if (!res.ok) {
          setStatus(RequestStatus.Rejected)
          return false
        }

        if (res.ok) setStatus(RequestStatus.Resolved)
      } catch (e) {
        setStatus(RequestStatus.Rejected)
      }
    }
    return null
  }, [])

  return { status, postData }
}

export default usePostData
