import { NavbarItems } from './Navbar.type'

export const items: Array<NavbarItems> = [
  {
    id: '1',
    title: 'Sobre o Evento',
    target: 'sobre'
  },
  {
    id: '2',
    title: 'Oportunidades',
    target: 'oportunidades'
  },
  {
    id: '3',
    title: 'Local do Evento',
    target: 'local'
  }
]
