import { Heading, Text } from '@/components'
import Image from 'next/image'

import Wrapper from '../Wrapper'
import { items } from './LastEdition.data'

const LastEdition = () => (
  <Wrapper
    style={{ backgroundImage: 'url(/fundo-ultima-edicao.jpg)' }}
    className="bg-teal-600 bg-cover py-12 lg:py-24"
    id="oportunidades"
  >
    <div className="flex flex-col gap-8">
      <Image
        className="mx-auto"
        src="/simbolo-bc-branco.svg"
        alt="BC Energia"
        width={66}
        height={48}
      />
      <Heading center className="text-white">
        Nossa última edição foi um sucesso!
      </Heading>
      <div className="m-auto w-2/3">
        <Text center className="text-white">
          Palestras de altíssimo nível sobre a Abertura do Mercado de Energia, Segurança e
          Governança de Mercado e Digitalização e Desburocratização do Setor.
        </Text>
      </div>
      <div className="mt-4 flex flex-col gap-4 lg:mt-10 lg:flex-row lg:gap-8">
        <div className="flex basis-1/2 gap-4 lg:gap-10">
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:gap-10">
            {items.map((item) => (
              <div key={item.id} className="content-center bg-white p-2 text-center">
                <Heading center as="h4" size="lg" className="text-teal-600">
                  {item.title}
                </Heading>
                <Heading center as="h6" size="xs">
                  {item.subtitle}
                </Heading>
                <Text center className="mt-5">
                  {item.description}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div
          title="Ver vídeo"
          className="min-h-auto group flex basis-1/2 gap-10 rounded bg-cover hover:cursor-pointer lg:min-h-[26rem]"
        >
          <iframe
            className="h-[20rem] lg:h-full"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/3dtNxCTgDbk?si=-gOzoVXO4Q2j-SQp"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default LastEdition
