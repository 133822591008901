import { tv } from 'tailwind-variants'

export const buttonStyles = tv({
  base: 'bg-teal-900 px-6 py-3 font-bold uppercase text-white transition hover:cursor-pointer hover:bg-teal-800 disabled:bg-teal-600 disabled:hover:cursor-not-allowed',
  variants: {
    block: {
      true: 'block w-full text-center'
    }
  }
})
