import { PostDataProps } from '@/hooks/salesForce.type'

export const postSalesForm = async (data: PostDataProps) =>
  await fetch(`/api/application`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
