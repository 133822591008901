import { FC } from 'react'

import Image from 'next/image'

const Content: FC = () => (
  <>
    <h1 className="px-4 text-3xl uppercase leading-snug text-white sm:px-0 sm:text-3xl md:text-4xl md:leading-[3rem] lg:pr-8 lg:text-5xl lg:leading-[3.7rem] xl:text-5xl xl:leading-[3.8rem]">
      <span className="sm:leading-15 mb-6 inline-block border-4 border-amber-400 px-4 py-1 text-7xl text-amber-400 md:text-[6rem] md:leading-[7rem] lg:text-[7rem] lg:leading-[9rem] xl:text-[7rem] xl:leading-[8rem] ">
        9º
      </span>
      <br />
      Encontro dos agentes do setor elétrico{' '}
      <span className="block text-2xl text-amber-400 md:text-3xl">no centro-oeste</span>
    </h1>
    <span className="z-10 mt-2 flex justify-center gap-4 bg-amber-400 px-6 py-3 text-xl font-bold text-teal-800 md:px-0 lg:ml-[-100%] lg:mt-5 lg:justify-normal lg:py-4 lg:pl-[100%] lg:text-[1.2rem] xl:text-2xl">
      <span className="hidden md:flex">
        <Image
          className="hidden sm:block"
          src="/calendar.svg"
          alt="BC Energia"
          width={22}
          height={24}
          priority
        />{' '}
      </span>
      <span>14 de Agosto de 2024 | Às 14h - Goiânia - GO</span>
    </span>
  </>
)
export default Content
