// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
'use client'
import { useCallback, useState } from 'react'
import Carousel, { Modal, ModalGateway, ViewType } from 'react-images'
import Gallery from 'react-photo-gallery'

import { photos } from './Photos.source'

const Photos = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((_: unknown, { index }: { index: number }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <section id="fotos" className="bg-white p-0">
      <div>
        <Gallery photos={photos} onClick={openLightbox} margin={0} />
        <ModalGateway>
          {viewerIsOpen && (
            <Modal onClose={closeLightbox} allowFullscreen={false}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(
                  (photo) =>
                    ({
                      width: photo.width,
                      height: photo.height,
                      source: photo.src
                    }) as ViewType
                )}
              />
            </Modal>
          )}
        </ModalGateway>
      </div>
    </section>
  )
}

export default Photos
