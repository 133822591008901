import { OportunityItemsType } from '@/components/Sections/Opportunity/Opportunity.type'

export const items: Array<OportunityItemsType> = [
  {
    id: '1',
    title: 'Temas Exclusivos',
    description:
      'Em 2024, contaremos com a participação de especialistas para tratar de assuntos importantes que ditam o setor elétrico na atualidade e tendências para o futuro.',
    icon: '/temas-exclusivos.svg',
    iconSize: [61, 75],
    background: '/temas-exclusivos-fundo.jpg'
  },
  {
    id: '2',
    title: 'Networking',
    description:
      ' Uma oportunidade para estar em contato com players do mercado de centenas de empresas, especialistas e membros de associações e órgãos governamentais e reguladores.',
    icon: '/networking.svg',
    iconSize: [90, 60],
    background: '/networking-fundo.jpg'
  },
  {
    id: '3',
    title: 'Oportunidade',
    description:
      'Mergulhe em um ambiente propício para construir relacionamentos sólidos com um público altamente qualificado, composto por profissionais e potenciais parceiros do setor.',
    icon: '/oportunidades.svg',
    iconSize: [63, 69],
    background: '/oportunidade-2.jpg'
  }
]
