import { tv } from 'tailwind-variants'

export const headingStyles = tv({
  base: 'text-center uppercase lg:text-4xl xl:text-left',
  variants: {
    center: {
      true: 'xl:text-center'
    },
    size: {
      xs: 'text-1xl md:text-1xl lg:text-1xl xl:text-1xl',
      sm: 'text-1xl md:text-1xl lg:text-2xl xl:text-2xl',
      md: 'text-2xl md:text-2xl lg:text-3xl xl:text-3xl',
      lg: 'text-2xl md:text-3xl lg:text-4xl xl:text-4xl',
      xl: 'text-2xl md:text-4xl xl:text-5xl'
    }
  }
})
