import { FC } from 'react'

import Image from 'next/image'

const Footer: FC = () => (
  <>
    <footer
      className="px:lg-0 relative mt-auto bg-zinc-900 bg-cover px-8 py-24"
      style={{ backgroundImage: `url('/footer-bg.png')` }}
    >
      <div className="container mx-auto text-center">
        <Image
          className="mx-auto mb-14"
          src="/Logo-BC-Energia-Vertical-Branco.svg"
          alt="BC Energia"
          width={257}
          height={148}
        />

        <div className="text-white md:flex md:justify-center  md:space-x-10">
          <div className="mt-2 md:mt-0 md:flex-1">
            <h2 className="mb-2 text-lg">Endereço em Goiânia:</h2>
            <p>
              Av. Dep. Jamel Cecílio, c/ rua 56, nº 2929, Salas 2802/2803 - Ed. Brookfield Towers
              Torre B - Jardim Goiás, Goiânia - GO, 74810-240
            </p>
          </div>
          <div className="mt-10 md:mt-0 md:flex-1">
            <h2 className="mb-2 text-lg">Endereço em São Paulo:</h2>
            <p>
              Av. Pres. Juscelino Kubitschek, 360 - 7º andar cj71 - Edifício JK 360 -Vila Nova
              Conceição, São Paulo - SP, 04543-000
            </p>
          </div>
        </div>
      </div>
    </footer>
    <div className="bg-teal-600 pb-5 pt-6 text-white">
      <div className="container mx-auto text-center">
        <strong>© GRUPO BC ENERGIA - 2024</strong> | TODOS OS DIREITOS RESERVADOS
      </div>
    </div>
  </>
)

export default Footer
