import { headingStyles } from './Heading.style'
import { HeadingProps } from './Heading.type'

const Heading = ({ children, center, as = 'h1', size = 'xl', className }: HeadingProps) => {
  const Component = as

  return (
    <div>
      <Component className={`${headingStyles({ center, size })} ${className}`}>
        {children}
      </Component>
    </div>
  )
}

export default Heading
