import { tv } from 'tailwind-variants'

export const textStyles = tv({
  base: 'text-center xl:text-left',
  variants: {
    center: {
      true: 'xl:text-center'
    }
  }
})
