import { RequestStatus } from '@/components/ApplicationForm/ApplicationForm.type'

import { styles } from './Fields.style'
import { InputProps } from './Fields.type'

const Select = ({ status, register, ...rest }: InputProps) => (
  <div
    style={{ gridColumn: rest.configuration && rest.configuration?.columns === 1 ? '1 / -1' : '' }}
  >
    <select
      required
      disabled={status === RequestStatus.Pending}
      {...register(rest.name)}
      className={`${styles.input} bg-white py-[0.63rem]`}
    >
      <option value="" disabled selected>
        {rest.placeholder}
      </option>
      {rest?.options?.map((state) => (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      ))}
    </select>
  </div>
)

export default Select
