import { FC } from 'react'

import Image from 'next/image'

import Content from './Content'
import Form from './Form'

const Home: FC = () => (
  <section
    id="home"
    className="flex h-auto min-h-screen flex-col content-center justify-center bg-hero-pattern bg-cover bg-center pb-16 pt-0 sm:pt-0 md:pt-36 lg:pb-0 lg:pt-28"
  >
    <div className="mb-6 flex p-4 md:hidden">
      <div className="container m-auto mx-auto flex justify-center rounded-lg bg-white bg-opacity-30 py-4 backdrop-blur-lg">
        <Image src="/logo-bc-energia.svg" alt="BC Energia" width={190} height={44} priority />
      </div>
    </div>
    <div className="container mx-auto p-0">
      <div className="flex flex-col space-x-0 text-center md:space-x-0 lg:flex-row lg:space-x-12 lg:text-left xl:space-x-32">
        <div className="flex flex-1 flex-col justify-between gap-4">
          <Content />
        </div>
        <div className="flex flex-1 justify-center lg:mt-0">
          <Form />
        </div>
      </div>
    </div>
  </section>
)

export default Home
