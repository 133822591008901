import { Heading, Text } from '@/components'
import Image from 'next/image'

const Local = () => (
  <section id="local" className="bg-white p-0">
    <div className="l-0 r-0 container mx-auto max-w-full bg-teal-600 bg-opacity-90 p-8">
      <div className="flex flex-col gap-6 text-white">
        <Image src="/local.svg" alt="Local" width={38} height={53} className="mx-auto" />
        <Heading center>Local do Evento</Heading>
        <Text center>
          O evento será realizado na ASMEGO - Associação dos Magistrados do Estado de Goiás R. 72,
          192 - Jardim Goiás, Goiânia - GO, 74805-480
        </Text>
      </div>
    </div>
    <iframe
      className=""
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.5178202198304!2d-49.2338609076538!3d-16.700995316974506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef1ade4d55529%3A0xa56c1d67b449731b!2sASMEGO%20-%20Associa%C3%A7%C3%A3o%20dos%20Magistrados%20do%20Estado%20de%20Goi%C3%A1s!5e0!3m2!1spt-BR!2sbr!4v1713105892456!5m2!1spt-BR!2sbr"
      width="100%"
      height="500"
      style={{ border: 0 }}
      allowFullScreen={false}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </section>
)

export default Local
