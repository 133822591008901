export const genreData = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' }
]

export const ageGroupData = [
  { value: '18 a 25 anos', label: '18 a 25 anos' },
  { value: '26 a 35 anos', label: '26 a 35 anos' },
  { value: '36 a 45 anos', label: '36 a 45 anos' },
  { value: '46 a 55 anos', label: '46 a 55 anos' },
  { value: '56 a 65 anos', label: '56 a 65 anos' },
  { value: 'Acima de 65 anos', label: 'Acima de 65 anos' }
]
