import { Heading, Text } from '@/components'
import Image from 'next/image'

import Wrapper from '../Wrapper'
import { items } from './Opportunity.data'

const Opportunity = () => (
  <Wrapper id="oportunidades">
    <Heading center>Porque Participar?</Heading>
    <div className="mt-12 flex flex-col gap-12 md:mb-8 lg:mb-10 lg:mt-20 lg:flex-row xl:mb-20">
      {items.map((item) => (
        <div
          key={item.id}
          className="flex basis-1/3 flex-col gap-10 transition duration-700 xl:hover:scale-105"
        >
          <div className="min-h-[30rem] overflow-hidden rounded-lg bg-teal-600 text-center">
            <div
              style={{ backgroundImage: `url(${item.background})` }}
              className="flex items-center justify-center bg-teal-300 bg-cover py-20"
            >
              <Image
                src={item.icon}
                alt="Icone"
                width={item.iconSize[0]}
                height={item.iconSize[1]}
              />
            </div>
            <div className="flex h-fit flex-col items-center gap-6 px-6 pb-4 pt-10 text-white">
              <Heading as="h3" size="sm">
                {item.title}
              </Heading>
              <Text center>{item.description}</Text>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Wrapper>
)

export default Opportunity
