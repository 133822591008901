import { ReactNode, CSSProperties } from 'react'

type Props = {
  id: string
  children?: ReactNode
  style?: CSSProperties
  className?: string
}

const Wrapper = ({ id, children, ...res }: Props) => (
  <section id={id} className="bg-white pb-8 pt-12 lg:pt-24" {...res}>
    <div className="container mx-auto p-0">{children}</div>
  </section>
)

export default Wrapper
